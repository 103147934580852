import { ApolloClientOptions, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import React, { ReactNode } from 'react';

import { getClient } from '@/api/config';

export type ApolloClientProviderProps = {
  children: ReactNode;
  clientOptions?: Omit<ApolloClientOptions<NormalizedCacheObject>, 'cache'>
}

const ApolloClientProvider: React.FC<ApolloClientProviderProps> = ({ children, clientOptions = {} }) => (
  <ApolloProvider client={getClient(clientOptions)}>
    {children}
  </ApolloProvider>
);

export default ApolloClientProvider;
