export const getMeta = (metaName: string) => {
  const metas = document.getElementsByTagName('meta')

  for (const meta of metas) {
    if (meta.getAttribute('name') === metaName) {
      return meta.getAttribute('content') ?? ''
    }
  }

  return ''
}
