import cx from 'clsx';
import React from 'react';

import classes from './ErrorCatcher.module.css';

type ErrorCatcherProps = {
  code?: string | number;
  airbrakeId: string;
};

const SUPPORT_LINK = 'https://foodacustomerhelp.atlassian.net/servicedesk/customer/portal/1/group/12/create/16'

const ErrorCatcher: React.FC<ErrorCatcherProps> = ({ code = 500, airbrakeId }) => {
  const link = new URL(SUPPORT_LINK);
  link.searchParams.append('description', `Referred from error page with Airbrake ID: ${airbrakeId}`);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.label}>{code}</div>
        <h1 className={classes.title}>A surprising twist just occurred!</h1>
        <p className={classes.description}>
          Our servers could not handle your request. Don&apos;t worry, our development team was
          already notified. Try refreshing the page.
        </p>
        <div className={classes.group}>
          <button className={classes.button} onClick={window.location.reload}>Refresh the page</button>
        </div>
        <p className={cx(classes.description, classes.repeatedly)}>
          Airbrake ID: <b>{airbrakeId}</b>
          <br/>
          If this happens repeatedly, you can <a href={link.toString()} target="_blank" rel="noreferrer">submit a ticket at Fooda's help center.</a>
        </p>
      </div>
    </div>
  );
};

export default ErrorCatcher;
