import React, { PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { getMeta } from '@/helpers/get_meta';

const CaptchaProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <GoogleReCaptchaProvider reCaptchaKey={getMeta('recaptcha-site-key')}>
    {children}
  </GoogleReCaptchaProvider>
);

export default CaptchaProvider;
