import './global.css';

import {
  Button,
  createTheme,
  CSSVariablesResolver,
  LoadingOverlay,
  MantineProvider,
  Modal,
  MultiSelect,
  Select,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import React from 'react';

import AirbrakeErrorBoundary from './AirbrakeBoundary';
import ApolloClientProvider, { ApolloClientProviderProps } from './ApolloClientProvider';

export type ThemeProviderProps = ApolloClientProviderProps;

const AdminProvider: React.FC<ThemeProviderProps> = ({ children, clientOptions }) => (
  <AirbrakeErrorBoundary>
    <ApolloClientProvider clientOptions={clientOptions}>
      <MantineProvider theme={theme} cssVariablesResolver={resolver} defaultColorScheme="light">
        <ModalsProvider modalProps={{ zIndex: 1300 }}>
          <Notifications position="bottom-right" autoClose={2000} zIndex={1400} />
          {children}
        </ModalsProvider>
      </MantineProvider>
    </ApolloClientProvider>
  </AirbrakeErrorBoundary>
);

export default AdminProvider;

const theme = createTheme({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSmoothing: false,
  cursorType: 'pointer',
  components: {
    Modal: Modal.extend({
      defaultProps: {
        overlayProps: { backgroundOpacity: 0.55, blur: 3 },
        transitionProps: { duration: 300 },
        zIndex: 1100,
      },
    }),
    LoadingOverlay: LoadingOverlay.extend({
      defaultProps: {
        overlayProps: { blur: 2 },
        loaderProps: { type: 'dots' },
      },
    }),
    Button: Button.extend({
      defaultProps: {
        loaderProps: { type: 'dots' },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        comboboxProps: { zIndex: 1200 },
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        comboboxProps: { zIndex: 1200 },
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        popoverProps: { zIndex: 1200 },
      },
    }),
  },
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--snappea-color-body': '#FFFFFF',
    '--snappea-color-text': '#333333',
    '--snappea-font-size-text': theme.fontSizes.sm,
  },
  light: {},
  dark: {},
});
