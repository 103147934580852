import React, { Component, ErrorInfo } from 'react';

import ErrorCatcher from '@/components/shared/ErrorCatcher';
import notify from '@/helpers/airbrake';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  id: string;
};

class AirbrakeErrorBoundary extends Component<Props, State> {
  state = { hasError: false, id: '' };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const response = notify({ error, params: { info } });
    if (response) {
      response.then(({ id = '' }) => this.setState({ id, hasError: true }));
    }
  }

  render() {
    if (!this.state.hasError) {
      return <>{this.props.children}</>;
    }

    // airbrake is disabled
    if (!window.__AIRBRAKE_CONFIG__) {
      return <>A surprising twist just occurred!</>;
    }

    return <ErrorCatcher code={500} airbrakeId={this.state.id} />;
  }
}

export default AirbrakeErrorBoundary;
