import './global.css';

import {
  createTheme,
  CSSVariablesResolver,
  HoverCard,
  Loader,
  LoadingOverlay,
  MantineProvider,
  Menu,
  Modal,
  Select,
  Popover,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import React from 'react';

import AirbrakeErrorBoundary from './AirbrakeBoundary';
import ApolloClientProvider, { ApolloClientProviderProps } from './ApolloClientProvider';
import CaptchaProvider from './CaptchaProvider';

export type ThemeProviderProps = ApolloClientProviderProps;

const AppProvider: React.FC<ThemeProviderProps> = ({ children, clientOptions }) => (
  <AirbrakeErrorBoundary>
    <ApolloClientProvider clientOptions={clientOptions}>
      <MantineProvider theme={theme} cssVariablesResolver={resolver} defaultColorScheme="light">
        <ModalsProvider>
          <Notifications position="bottom-right" autoClose={2000} zIndex={5010} />
          <CaptchaProvider>{children}</CaptchaProvider>
        </ModalsProvider>
      </MantineProvider>
    </ApolloClientProvider>
  </AirbrakeErrorBoundary>
);

export default AppProvider;

const theme = createTheme({
  fontSmoothing: false,
  fontFamily: '"Open Sans", sans-serif;',
  components: {
    Modal: Modal.extend({
      defaultProps: {
        overlayProps: { backgroundOpacity: 0.4, blur: 15 },
        transitionProps: { duration: 300 },
        zIndex: 5000,
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        type: 'dots',
        color: 'orange',
      },
    }),
    Select: Select.extend({
      defaultProps: {
        comboboxProps: { zIndex: 5100 },
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        zIndex: 5200,
      },
    }),
    HoverCard: HoverCard.extend({
      defaultProps: {
        zIndex: 5100,
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        zIndex: 5300,
      },
    }),
    LoadingOverlay: LoadingOverlay.extend({
      defaultProps: {
        overlayProps: { radius: 'sm', blur: 2, bg: 'transparent' },
      },
    }),
  },

  colors: {
    gray: ['#FFFFFF','#F5F5F5','#C4C4C4','#A6A6A6','#979797','#898989','#7A7A7A','#6B6B6B','#5C5C5C','#3D3D3D'],
    dark: ['#A3A3A3', '#949494', '#858585', '#767676', '#686868', '#595959', '#3B3B3B', '#1E1E1E', '#141414', '#000000'],
    orange: ['#FEEFED', '#FDE0DB', '#FBD0C9', '#FAC1B7', '#F8A192', '#F5826E', '#F3634A', '#C6503B', '#9A3D2D', '#6D2A1E'],
    blue: ['#E9F3FE', '#D2E7FC', '#BCDBFB', '#A6CFF9', '#79B6F6', '#4D9EF3', '#2086F0', '#1A6BC0', '#135090', '#0D3660'],
    lime: ['#FFFCEE', '#FEF5CC', '#FEF5CC', '#FDF1BB', '#FDEB9A', '#FCE478', '#FBDD56', '#D1B745', '#A79234', '#7C6C23'],
    grape: ['#FFF8F6', '#FFF0EE', '#FEE9E5', '#FEE2DD', '#FED3CB', '#FDC5BA', '#FDB6A9', '#E39688', '#C97667', '#A24635'],
    violet: ['#F8F7FD', '#F0EEFA', '#E9E6F8', '#E1DDF6', '#D3CDF1', '#C4BCED', '#B5ABE8', '#978DCA', '#7A70AD', '#5C528F'],
    teal: ['#E8FAF5', '#D1F5EB', '#BAF0E1', '#A3EBD7', '#74E0C3', '#46D6AF', '#18CC9B', '#13A37C', '#0E7A5D', '#0A523E'],
    indigo: ['#E2EDF4', '#CEE0EB', '#B9D2E2', '#A4C4D9', '#7BA9C8', '#518DB6', '#2872A4', '#205B83', '#184462', '#102E42'],
    green: ['#C7FFE4', '#9FECC7', '#8BE2B8', '#77D8AA', '#50C58C', '#28B16F', '#009E52', '#007E42', '#005F31', '#003F21'],
    yellow: ['#FFFAE6', '#FEF6CC', '#FEF1B3', '#FDEC99', '#FCE366', '#FBD933', '#FAD000', '#C8A600', '#967D00', '#645300'],
    red: ['#FCE9E9', '#F8D2D2', '#F5BCBC', '#F1A6A6', '#EA7979', '#E34D4D', '#DC2020', '#B01A1A', '#841313', '#580D0D'],
  },
  fontSizes: {
    xxxs: '0.5rem',
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  spacing: {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    xxl: '2rem',
  },
  defaultRadius: 'md',
  radius: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  headings: {
    fontWeight: '400',
    sizes: {
      h1: { fontSize: '2.125rem', lineHeight: '1.3' },
      h2: { fontSize: '1.625rem', lineHeight: '1.35' },
      h3: { fontSize: '1.375rem', lineHeight: '1.4' },
      h4: { fontSize: '1.125rem', lineHeight: '1.45' },
      h5: { fontSize: '1rem', lineHeight: '1.5' },
      h6: { fontSize: '0.875rem', lineHeight: '1.5' },
    },
  },
  cursorType: 'pointer',
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--snappea-color-body': '#F9F8F7',
    '--snappea-color-text': '#222222',
    '--snappea-font-size-text': theme.fontSizes.md,
  },
  light: {},
  dark: {},
});
